<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md5 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				{{ getTitle() }}
			</v-flex>
			<v-flex md7 class="font-level-3-bold my-auto d-flex justify-content-end" v-if="false">
				<v-btn color="blue darken-4 white--text" tile deppressed>Save</v-btn>
			</v-flex>
		</v-layout>
		<Loading v-if="contentLoading"></Loading>
		<div v-else class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th width="30" class="p-2 blue lighten-4 text-center" v-if="false"></th>
						<th width="50" class="p-2 blue lighten-4 text-center">#</th>
						<th class="p-2 blue lighten-4">GRN Number</th>
						<th class="p-2 blue lighten-4">Item</th>
						<th class="p-2 blue lighten-4 text-center">Total</th>
						<th class="p-2 blue lighten-4 text-center">Received</th>
					</tr>
				</thead>
				<!-- <tbody v-if="line_items.length"> -->
				<Draggable
					v-if="line_items.length"
					tag="tbody"
					v-model="line_items"
					class="draggable-group"
					handle=".draggable-drag-icon"
				>
					<tr v-for="(row, index) in line_items" :key="index">
						<td class="p-2 border-top-light-grey text-center" v-if="false">
							<v-icon color="blue darken-4" class="cursor-move draggable-drag-icon">mdi-drag</v-icon>
						</td>
						<td class="p-2 border-top-light-grey text-center">
							{{ index + 1 }}
						</td>
						<td class="p-2 border-top-light-grey">
							{{ row.grn_barcode }}
						</td>
						<td class="p-2 border-top-light-grey">
							{{ row.product_name }}
						</td>
						<td class="p-2 border-top-light-grey text-center">
							<v-chip label>
								{{ row.required_qty }}
							</v-chip>
						</td>
						<td class="p-2 border-top-light-grey text-center">
							<v-chip label color="green white--text">
								{{ row.received_qty }}
							</v-chip>
						</td>
					</tr>
				</Draggable>
				<!-- </tbody> -->
				<tfoot v-else>
					<tr>
						<td colspan="5">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no {{ getTitle() }} at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>
<script>
import Draggable from "vuedraggable";
import Loading from "@/view/components/Loading";
import { GetLineItem } from "@/core/lib/purchase-order.lib";
export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		uuid: {
			type: String,
			default: null,
		},
		id: {
			type: Number,
			default: 0,
		},
		status: {
			type: String,
			default: "all",
		},
	},
	data() {
		return {
			contentLoading: false,
			line_items: [],
			pageLoading: true,
		};
	},
	methods: {
		getTitle() {
			return "Recieved";
		},
		updateValue(value) {
			console.log("UpdateValue", value);
		},
		async getRecievedItem(id) {
			const param = {
				purchase_order: id,
				option: "received_line_items",
			};
			this.contentLoading = true;
			const data = await GetLineItem(param);
			this.line_items = data.line_items;
			this.contentLoading = false;
		},
	},
	components: {
		Draggable,
		Loading,
	},
	mounted() {
		if (this.id) {
			this.getRecievedItem(this.id);
		}
	},
};
</script>
